import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import './App.css';

function Chat() {
  const { botId } = useParams();
  const [userInput, setUserInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const messagesEndRef = useRef(null);
  const currentBotMessage = useRef("");
  const [showUserInfoForm, setShowUserInfoForm] = useState(false);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [firstMessageSent, setFirstMessageSent] = useState(false);
  const [pendingUserInput, setPendingUserInput] = useState('');

  useEffect(() => {
    const generateSessionId = () => {
      const timestamp = new Date().getTime();
      const randomNum = Math.floor(Math.random() * 10000) + 1;
      return `${timestamp}-${randomNum}`;
    };
    setSessionId(generateSessionId());

    const fetchWelcomeMessage = async () => {
      try {
        const welcomeMessage = `I am **CloEE**, a digital assistant designed to enhance equipment management efficiency. Here are some of the key functionalities I offer:

- **Current Machine Statuses**: I can provide real-time operational statuses of your machines.
- **Historical Performance Data**: I can fetch historical performance data for your machines, including metrics such as availability percentage, planned working time, actual working time, and detailed status distribution over a specified period.

If you have any specific questions or need assistance with your machines, feel free to ask!`;
        setMessages([
          {
            text: welcomeMessage,
            from: 'bot'
          }
        ]);
        scrollToBottom();
      } catch (error) {
        console.error('Error fetching welcome message:', error);
        setMessages([
          {
            text: "Failed to load welcome message from server.",
            from: 'bot'
          }
        ]);
      }
    };

    fetchWelcomeMessage();
  }, [botId]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const sendMessage = async (customMessage = null, skipUserMessage = false) => {
    const messageToSend = customMessage || userInput;

    if (!firstMessageSent) {
      setFirstMessageSent(true);
      const userMessage = { text: messageToSend, from: 'user' };
      const botResponse = {
        text: "Before we continue, I'll need some information from you. Could you please provide your name and email address?",
        from: 'bot',
        showUserInfoForm: true
      };
      setPendingUserInput(messageToSend);
      setMessages(messages => [...messages, userMessage, botResponse]);
      setUserInput('');
      setShowUserInfoForm(true);
      return;
    }

    if (!skipUserMessage) {
      const userMessage = { text: messageToSend, from: 'user' };
      setMessages(messages => [...messages, userMessage]);
    }

    setUserInput('');
    setLoading(true);
    currentBotMessage.current = "";

    const maxRetries = 3;
    let retryCount = 0;

    const attemptConnection = () => {
      return new Promise((resolve, reject) => {
        const eventSource = new EventSource(`/api/chat_response?user_input=${encodeURIComponent(messageToSend)}&session_id=${sessionId}&advisor_id=172856341936763900538jkuGJ`);

        let isFirstMessage = true;
        let timeoutId;

        eventSource.onmessage = (event) => {
          const chunk = event.data;
          if (chunk !== 'end of response') {
            if (isFirstMessage) {
              setLoading(false);
              isFirstMessage = false;
              clearTimeout(timeoutId);
            }
            const parsedChunk = chunk.replace(/<newline>/g, '\n');
            currentBotMessage.current += parsedChunk;
            setMessages(messages => {
              const lastMessage = messages[messages.length - 1];
              if (lastMessage && lastMessage.from === 'bot') {
                return [...messages.slice(0, -1), { ...lastMessage, text: currentBotMessage.current }];
              } else {
                return [...messages, { text: currentBotMessage.current, from: 'bot' }];
              }
            });
          }
        };

        eventSource.onerror = (error) => {
          console.error('Error fetching response:', error);
          if (isFirstMessage) {
            reject(new Error('Failed to get response from server.'));
          }
          eventSource.close();
        };

        eventSource.addEventListener('end', () => {
          setMessages(messages => {
            const lastMessage = messages[messages.length - 1];
            if (lastMessage && lastMessage.from === 'bot') {
              return [...messages.slice(0, -1), { ...lastMessage, text: currentBotMessage.current }];
            } else {
              return [...messages, { text: currentBotMessage.current, from: 'bot' }];
            }
          });
          eventSource.close();
          resolve();
        });

        timeoutId = setTimeout(() => {
          if (isFirstMessage) {
            eventSource.close();
            reject(new Error('No response received from server.'));
          }
        }, 30000);
      });
    };

    const retryConnection = async () => {
      while (retryCount < maxRetries) {
        try {
          await attemptConnection();
          return;
        } catch (error) {
          retryCount++;
          console.log(`Attempt ${retryCount} failed. Retrying...`);
          if (retryCount >= maxRetries) {
            setLoading(false);
            setMessages(messages => [...messages, { text: `Failed to get response after ${maxRetries} attempts.`, from: 'bot' }]);
            throw error;
          }
        }
      }
    };

    try {
      await retryConnection();
    } catch (error) {
      console.error('Error fetching response:', error);
      setLoading(false);
    }
  };

  const isValidEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const startConversation = async () => {
    if (userName && userEmail) {
      if (isValidEmail(userEmail)) {
        try {
          const response = await fetch('/api/chat_registration', {
            method: 'POST',
            body: JSON.stringify({
              name: userName,
              email: userEmail,
              session_id: sessionId,
              advisor_id: '172856341936763900538jkuGJ'
            }),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          });
          if (response.ok) {
            setShowUserInfoForm(false);
            setMessages(messages => messages.filter(msg => !msg.showUserInfoForm));
            sendMessage(pendingUserInput, true);
          } else {
            alert('Failed to submit user info.');
          }
        } catch (error) {
          console.error('Error submitting user info:', error);
          alert('Failed to submit user info.');
        }
      } else {
        alert("Please enter a valid email address.");
      }
    } else {
      alert("Please enter your name, email and agree the privacy policy to start the conversation.");
    }
  };

  const LinkRenderer = (props) => {
    return (
      <a href={props.href} target="_blank" rel="noopener noreferrer">
        {props.children}
      </a>
    );
  };

  return (
    <div className="chat-container">
      <div className="chat-parent">
        <div className='logo-messages-container'>
          <div className='logo-container'>
            <a href="https://cloee.co" target="_blank" rel="noopener noreferrer">
              <img src={`${process.env.PUBLIC_URL}/logo-black.svg`} alt="Logo" className='logo' />
            </a>
          </div>
          <div className="message-container">
            {messages.map((msg, index) => (
              <div key={index} className={`message ${msg.from === 'user' ? 'user-message' : 'bot-message'}`}>
                {msg.from === 'bot' && (
                  <div className="cloee-icon-container">
                    <img src="../cloee-icon.png" alt="Bot Icon" className='cloee-icon' />
                  </div>
                )}
                <div className={msg.from === 'user' ? 'user-chat-text' : 'bot-chat-text'}>
                  <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}
                    components={{
                      a: LinkRenderer
                    }}
                  >
                    {msg.text}
                  </ReactMarkdown>
                  {msg.showUserInfoForm && (
                    <div className="user-info-form">
                      <input
                        type="text"
                        placeholder="Your Name"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        autoComplete="name"
                      />
                      <input
                        type="email"
                        placeholder="Your Email"
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                        autoComplete="email"
                      />
                      <div className="consent-checkbox">
                        <label htmlFor="consent">By submitting your email, you consent to allow the storage and processing of your personal data in line with our <a href="http://cloee.co/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Statement</a> to provide you with the content requested.</label>
                      </div>
                      <div className="user-info-button-container">
                        <button onClick={startConversation}>Start Conversation</button>
                      </div>
                    </div>
                  )}
                </div>
                {msg.from === 'user' && (
                  <div className="user-icon-container">
                    <img src="../user-icon.png" alt="User Icon" className='user-icon' />
                  </div>
                )}
              </div>
            ))}
            {loading && <div className="loader"></div>}
            <div ref={messagesEndRef} />
          </div>
        </div>
        <div className="chat-input">
          <input
            type="text"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && !showUserInfoForm && userInput.trim() ? sendMessage() : null}
            disabled={showUserInfoForm}
            placeholder={showUserInfoForm ? "Please fill out the form above to continue" : "Type your message here..."}
          />
          <button
            onClick={() => {
              if (userInput.trim() && !showUserInfoForm) {
                sendMessage();
              }
            }}
            disabled={showUserInfoForm || !userInput.trim()}
          >
            <FontAwesomeIcon icon={faLocationArrow} className='send-icon'/>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Chat;